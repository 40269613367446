'use client';

// components
import { SplashScreen } from '@agp/ui/components/loading-screen';

// ----------------------------------------------------------------------

export default function Loading() {
	return <SplashScreen />;
}
